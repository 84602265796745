import { Menu, Layout } from 'antd'
import { UserOutlined, /* NodeIndexOutlined,*/ ApartmentOutlined, MenuUnfoldOutlined, MenuFoldOutlined, LogoutOutlined } from '@ant-design/icons'
import { logout } from '../../modules/fireb'
import { useNavigate } from 'react-router-dom'

const { Sider } = Layout;

const AppNav = ({ navCollapsed, setNavCollapsed }) => {
    let navigate = useNavigate()

    const _onMnuItmClick = ({ key }) => {

        if (key === 'aufZuKlappen') {
            setNavCollapsed(!navCollapsed)
        }
        else if (key === 'logout') {
            logout()
                .then(() => { })
                .catch(console.err)
        }
        else if (key === 'usrs') {
            navigate('/usrs')
        }
        else if (key === 'tnts') {
            navigate('/tnts')
        }
    }

    return <Sider collapsible style={{ padding: '8px 12px', borderRight: '1px solid #8E8E93', backgroundColor: 'transparent' }} trigger={null} collapsed={navCollapsed} width="230px">
        <Menu mode="vertical" defaultSelectedKeys={['prjs']} onClick={_onMnuItmClick}>

            <Menu.Item key="aufZuKlappen" icon={navCollapsed ? (<MenuUnfoldOutlined />) : (<MenuFoldOutlined />)}>
                {navCollapsed ? "Navigation aufklappen" : "Navigation zuklappen"}
            </Menu.Item>

            <Menu.Divider />

            <Menu.Item key="tnts" icon={<ApartmentOutlined />}>
                Mandanten
            </Menu.Item>

            {/* 
            <Menu.Item key="prjs" icon={<NodeIndexOutlined />}>
                Projekte
            </Menu.Item>
            */}

            <Menu.Item key="usrs" icon={<UserOutlined />}>
                Benutzer
            </Menu.Item>

            <Menu.Divider />

            <Menu.Item key="logout" icon={<LogoutOutlined />}>
                Abmelden
            </Menu.Item>
        </Menu>
    </Sider>
}

export default AppNav