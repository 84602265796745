import React, { useState } from 'react'
import { Input, Button, Form } from 'antd'

import { login } from '../../modules/fireb'

const Login = () => {
    const [working, setWorking] = useState(false)
    const [loginErr, setLoginErr] = useState(false)
    const [loginErrCode, setLoginErrCode] = useState()

    const _onFinish = ({ email, pwd }) => {

        setWorking(true)
        setLoginErr(false)

        login(email, pwd)
            .then(() => {
                setWorking(false)
            })
            .catch((error) => {
                setWorking(false)
                setLoginErr(true)
                setLoginErrCode(error.code)
            })
    }

    return <div style={{ display: 'flex', justifyContent: 'center', height: '100%', flexDirection: 'column' }}>

        <Form
            name="auth"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            onFinish={_onFinish}
            autoComplete="off"
        >
            <Form.Item
                label="E-Mail"
                name="email"
                rules={[{ required: true, message: 'Bitte Ihre E-Mail eingeben!' }, { type: 'email', message: 'Bitte eine gültige E-Mail eingeben!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Kennwort"
                name="pwd"
                rules={[{ required: true, message: 'Bitte Ihr Kennwort eingeben!' }]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <>
                    {loginErr && <div role="alert" class="ant-form-item-explain-error">Ihre Zugangsdaten konnten nicht erfolgreich überprüft werden! (Fehler-Code: {loginErrCode})</div>}
                    <Button htmlType="submit" loading={working}>
                        Anmelden!
                    </Button>
                </>
            </Form.Item>
        </Form>
    </div>
}

export default Login