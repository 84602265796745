import { remove, runInAction } from 'mobx'
import { getUuid } from '../../../modules/id-generator'
import root from '../../'

export const startLoading = (taskInfo) => {

    const id = getUuid()

    runInAction(() => {
        root.workProgress.loadingTasks[id] = {
            id,
            ti: taskInfo,
        }
    })

    return id
}

export const doneLoading = (id) => {
    runInAction(() => {
        remove(root.workProgress.loadingTasks, id)
    })
}

export const startPushing = (taskInfo) => {

    const id = getUuid()

    runInAction(() => {
        root.workProgress.pushingTasks[id] = {
            id,
            ti: taskInfo,
        }
    })

    return id
}

export const donePushing = (id) => {
    runInAction(() => {
        remove(root.workProgress.pushingTasks, id)
    })
}