import { observable } from 'mobx'

/* AUTH */
const _store = observable({
    uid: '-',

    get IsInit() {
        return this.uid !== '-'
    },

    get AuthUid() {
        return (this.uid !== '-') ? this.uid : ''
    }
})

export default _store