import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom'

const TntsMain = lazy(() => import('./tnts/TntsMain'))
const UsrsMain = lazy(() => import('./usrs/UsrsMain'))

function RouteDispatcher() {

    return <Suspense fallback={<div>pls wait</div>}>
        <Routes>
            <Route path='usrs/*' element={<UsrsMain />} />
            <Route path='tnts/*' element={<TntsMain />} />
            <Route path='*' element={<div>DASHBOARD</div>} />
        </Routes>
    </Suspense>
}

export default RouteDispatcher