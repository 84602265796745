import { BrowserRouter } from 'react-router-dom'
import { Layout } from 'antd'

import AppBody from './components/app/AppBody'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/antd.css'

import './css/main.css'

import WorkProgressFeedback from './components/work-progress/WorkProgressFeedback'

const { Header, Footer } = Layout

function App() {

  return <BrowserRouter>
    <Layout className="site-layout" style={{ height: '100vh' }}>

      <Header className="site-layout-background" style={{ backgroundColor: '#f1f1f1', padding: '0 30px', borderBottom: '1px solid #8E8E93' }}>
        <img src="/imgs/inorisk-logo.png" border="0" alt="iNoRisk Logo" height="50" />
      </Header>

      <AppBody />
      <WorkProgressFeedback />

      <Footer style={{ textAlign: 'center', backgroundColor: '#f1f1f1', height: '35px', borderTop: '1px solid #8E8E93', padding: '8px 10px' }}>
        © iNoRisk 2020  |  Datenschutz  |  Impressum  |  Kontakt
      </Footer>
    </Layout>
  </BrowserRouter>
}

export default App;