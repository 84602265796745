import { get, set } from 'lodash'
import { keys, runInAction } from 'mobx'
import root from '../../'
import { getRoles } from '../../../modules/api-service'
import { BugCrash } from '../../../modules/bug-crash'
import changes from '../../utils/changes'
import { doneLoading, startLoading } from '../../work-progress/actions'

export const requireRoleById = (roleId) => {

    const _role = root.roles[roleId]
    if (_role) {
        return _role
    }

    runInAction(() => {
        root.roles[roleId] = {
            data: {
                id: roleId
            },
            rel: {
                permissions: {}
            },

            chgs: {},
            get isDirty() {
                return (keys(this.chgs).length > 0)
            },
            ...changes,
        }
    })

    return root.roles[roleId]
}

export const actLoadRoles = async () => {

    let _wpId

    try {
        _wpId = startLoading(`actLoadRoles`)

        const _roles = await getRoles()
        actSyncRolesInStore(get(_roles, ['list'], []))
    }
    catch (err) {
        BugCrash.notifyExt(`actLoadRoles`, err)
    }
    finally {
        if (_wpId) {
            doneLoading(_wpId)
        }
    }
}

export const actSyncRolesInStore = (roles) => {

    const _srvRoleIds = {}

    runInAction(() => {

        for (const { id, name, timestamp, permissions } of roles) {
            _srvRoleIds[id] = true

            const _clntRole = requireRoleById(id)

            if (get(_clntRole, ['data', 'timestamp'], 0) === timestamp) {
                continue
            }

            if (get(_clntRole, ['data', 'name'], '-') !== name) {
                set(_clntRole, ['data', 'name'], name)
            }
            if (get(_clntRole, ['data', 'timestamp'], 0) !== timestamp) {
                set(_clntRole, ['data', 'timestamp'], timestamp)
            }

            const _srvPerms = {}
            for (const _srvPerm of permissions) {
                _srvPerms[_srvPerm] = true

                if (!get(_clntRole, ['rel', 'permissions', _srvPerm], false)) { //perm not yet set in client-role
                    set(_clntRole, ['rel', 'permissions', _srvPerm], true)
                }
            }

            for (const _clntPerm of keys(_clntRole.rel.permissions)) { // find all permissions in cache-role which are not on the server any longer and remove them
                if (!_srvPerms[_clntPerm]) {
                    delete _clntRole.rel.permissions[_clntPerm]
                }
            }
        }

        for (const _roleId of keys(root.roles)) {
            if (!_srvRoleIds[_roleId]) { // role does not exist any more
                delete root.roles[_roleId]
            }
        }
    })
}