import { observable } from 'mobx'
import { myLstDefaultPageSize } from '../../config/const'

/* uiAdmTntMod */
const _store = observable({

    custId: null,
    fromCustId: null,

    idsFromCust: [],

    search: '',
    page: 1,

    pageSize: myLstDefaultPageSize,

    total: 0,
    ids: [],
    selMan: null,

    searchFil: '',
    pageFil: 1,

    pageSizeFil: myLstDefaultPageSize,

    totalFil: 0,
    idsFil: [],

    isLoading: false,

    searchRoot: '',
    pageRoot: 1,
    totalRoot: 0,
    idsRoot: [],

    pageSizeRoot: myLstDefaultPageSize,

    /* Popup to select a root tenant */
    searchSelRoot: '',
    pageSelRoot: 1,
    totalSelRoot: 0,
    idsSelRoot: [],

    pageSizeSelRoot: myLstDefaultPageSize,
})

export default _store