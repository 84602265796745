import { observable, values } from 'mobx'
import { orderBy } from 'lodash'

/* ROLES */
const _store = observable({
    get SortedRoles() {
        return orderBy(
            values(this).map(it => ({ id: it.data?.id, name: it.data?.name }))
            , [it => it.name?.toLowerCase()])

    }
})

export default _store