import { observable } from 'mobx'
import lblDe from '../../lbls/de'

/* SESSION */
const _store = observable({
    admSelCustId: null,
    lng: 'de',

    get Lbls() {

        if (this.lng === 'de') {
            return lblDe
        }

        return lblDe
    }
})

export default _store