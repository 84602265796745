import { observable, keys } from 'mobx'

/* WORK-PROGRESS */
const _store = observable({
    pushingTasks: {},
    loadingTasks: {},

    get hasPushingTasks() {
        return keys(this.pushingTasks).length > 0
    },

    get hasLoadingTasks() {
        return keys(this.loadingTasks).length > 0
    }
})

export default _store