import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

import { AsyncTrunk } from 'mobx-sync'
import rootStore from './store'
import { appVersion, appEnvironment } from './config/const'

//import reportWebVitals from './reportWebVitals';

if (appEnvironment === 'production') {
  Sentry.init({
    dsn: "https://db781a2b5f3f488aa92fe8d85dd2a280@o273787.ingest.sentry.io/6107861",
    integrations: [new Integrations.BrowserTracing()],
    release: "norisk-admink@" + appVersion,
    environment: appEnvironment,
    tracesSampleRate: 0.01,
  })
}

const trunk = new AsyncTrunk(rootStore.session, { storage: localStorage, storageKey: 'session_state' })

trunk.init().then(() => {
  ReactDOM.render(<App />, document.getElementById('root'))
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();