import { get, set } from 'lodash'
import { runInAction } from 'mobx'
import { computedFn } from 'mobx-utils'

const changes = {

    byPath: computedFn(function (path) {
        return get(
            this,
            ['chgs', ...path],
            get(
                this,
                ['data', ...path],
                ''
            )
        )
    }),

    chg(path, val) {

        const _self = this

        if (!_self.chgs) {
            runInAction(() => {
                set(_self, ['chgs'], {})
            })
        }

        runInAction(() => {
            set(_self.chgs, [...path], val)
        })
    },

    resetChgs() {

        if (!this.chgs) {
            return
        }
        const _self = this

        runInAction(() => {
            set(_self, ['chgs'], {})
        })
    }
}

export default changes