import { useState, useEffect } from 'react'
import { Layout } from 'antd'
import Login from '../auth/Login'
import AppNav from './AppNav'

import { observer } from 'mobx-react-lite'

import rootStore from '../../store'
import RouteDispatcher from '../RouteDispatcher'
import { actLoadRoles } from '../../store/roles/actions'

const { Content } = Layout

const AppBody = () => {
    const [navCollapsed, setNavCollapsed] = useState(false)

    const _authInit = rootStore.auth.IsInit
    const _loggedIn = !!rootStore.auth.AuthUid

    useEffect(() => {
        if (_loggedIn) {
            actLoadRoles()
                .catch(console.error)
        }
    }, [_loggedIn])

    const _bodyBgColor = (_authInit && _loggedIn) ? 'white' : 'transparent'

    return <Layout style={{ backgroundColor: '#f1f1f1' }} >

        {_loggedIn && <AppNav navCollapsed={navCollapsed} setNavCollapsed={setNavCollapsed} />}

        <Content className="site-layout-background" style={{ margin: '8px 12px', backgroundColor: _bodyBgColor, overflowY: 'auto' }}>
            {(_authInit && _loggedIn) && <RouteDispatcher />}
            {(_authInit && !_loggedIn) && <Login />}
            {!_authInit && <div>Laden...</div>}
        </Content>
    </Layout>
}

export default observer(AppBody)