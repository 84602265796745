import axios from './api'

export const getCustUsers = async (custId) => {

    const { data } = await axios.get(`/customers/${custId}/users`)

    return data
}

export const getCustUserById = async (custId, usrId) => {

    const { data } = await axios.get(`/customers/${custId}/users/${usrId}`)

    return data
}

export const patchCustUserById = async (custId, usrId, changes) => {
    await axios.patch(`/customers/${custId}/users/${usrId}`, { data: changes })
}

export const getCustAdmins = async (custId) => {

    const { data } = await axios.get(`/customers/${custId}/admins`)

    return data
}

export const getMyAdmCusts = async () => {

    const { data } = await axios.get(`/my/adm-customers`)

    return data
}

export const addCustAdmin = async (custId, usrId) => {
    await axios.post(`/customers/${custId}/admins/${usrId}`)
}

export const removeCustAdmin = async (custId, usrId) => {
    await axios.delete(`/customers/${custId}/admins/${usrId}`)
}

export const getCustUserPrjs = async (custId, usrId) => {

    const { data } = await axios.get(`/customers/${custId}/users/${usrId}/projects`)

    return data
}

export const getCustPrjs = async (custId) => {

    const { data } = await axios.get(`/customers/${custId}/projects`)

    return data
}

export const getRoles = async () => {

    const { data } = await axios.get(`/roles`)

    return data
}

export const saveUsrRolesInPrj = async (usrId, prjId, roles) => {
    await axios.post(`/projects/${prjId}/roles/${usrId}`, { roles })
}

export const loadUsrRolesInPrj = async (usrId, prjId) => {

    const { data } = await axios.get(`/projects/${prjId}/roles/${usrId}`)

    return data
}

export const createProj = async (id, name, usersFromCustId, tenantsFromCustId) => {
    await axios.post(`/customers/${usersFromCustId}/projects/${id}`, { name, tenants_from_cust_id: tenantsFromCustId })
}

export const loadTnt = async (custId, fromCustId, tntId) => {

    const { data } = await axios.get(`/customers/${custId}/tenants-from-cust/${fromCustId}/${tntId}`)

    return data
}

export const patchTnt = async (custId, fromCustId, tntId, changes) => {

    await axios.patch(`/customers/${custId}/tenants-from-cust/${fromCustId}/${tntId}`, changes)
}

export const deleteTnt = async (custId, fromCustId, tntId) => {

    const { data } = await axios.delete(`/customers/${custId}/tenants-from-cust/${fromCustId}/${tntId}`)

    return data
}

export const loadPrjsByMountedTntId = async (custId, fromCustId, tntId) => {

    const { data } = await axios.get(`/customers/${custId}/tenants-from-cust/${fromCustId}/projects/by-mount/${tntId}`)

    return data
}

export const delMountFromPrj = async (prjId, tntId) => {
    await axios.delete(`/projects/${prjId}/mounts/${tntId}`)
}

export const addMountToPrj = async (prjId, tntId) => {
    await axios.post(`/projects/${prjId}/mounts/${tntId}`)
}

export const loadRootTnts = async (custId, fromCustId, page, pageSize, search) => {

    const { data } = await axios.get(`/customers/${custId}/tenants-light/${fromCustId}?page=${page}&maxPerPage=${pageSize}&search=` + encodeURIComponent(search))

    return data
}

export const loadFilialenTnts = async (custId, fromCustId, parentId, page, pageSize, search) => {

    const { data } = await axios.get(`/customers/${custId}/tenants-light/${fromCustId}?page=${page}&parent=${parentId}&maxPerPage=${pageSize}&search=` + encodeURIComponent(search))

    return data
}
