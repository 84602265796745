import root from '../../'
import { set } from 'lodash'
import { BugCrash } from '../../../modules/bug-crash'
import { runInAction } from 'mobx'

export const updateFromFirebaseUser = (usr) => {

    const uid = (usr) ? usr.uid : null

    if (uid) {
        BugCrash.setUsrId(uid)
    }
    else {
        BugCrash.clearUsr()
    }

    runInAction(() => {
        set(root.auth, 'uid', uid)
    })
}