import auth from './auth'
import custs from './custs'
import prjs from './prjs'
import roles from './roles'
import session from './session'
import workProgress from './work-progress'
import users from './users'
import tnts from './tnts'

import uiAdmTntMod from './ui-adm-tnt-mod'

const _store = {
    auth,
    custs,
    prjs,
    roles,
    session,
    tnts,
    workProgress,
    users,

    uiAdmTntMod,
}

export default _store