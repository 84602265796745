import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut, getIdToken } from "firebase/auth";
import { updateFromFirebaseUser } from "../store/auth/actions";

var firebaseConfig = {
    apiKey: "AIzaSyCM_l2QOrkWsWIc1FD_VxfG0WFOOqITtSE",
    authDomain: "inorisk-de.firebaseapp.com",
    databaseURL: "https://inorisk-de.firebaseio.com",
    projectId: "inorisk-de",
    storageBucket: "inorisk-de.appspot.com",
    messagingSenderId: "410074764652",
    appId: "1:410074764652:web:464de2e49ebf91d8e9cf49",
    measurementId: "G-2TYRV9G1H1"
};

// Initialize Firebase
initializeApp(firebaseConfig)

getAuth().onAuthStateChanged((usr) => {
    updateFromFirebaseUser(usr)
})

export const login = async (email, pwd) => {

    try {
        const { user } = await signInWithEmailAndPassword(getAuth(), email, pwd)
        return user
    }
    catch (err) {
        console.log(err)
        throw err
    }
}

export const logout = async () => {

    try {
        await signOut(getAuth())
    }
    catch (err) {
        console.log(err)
    }
}

export const getAccessToken = async (forceRefresh) => {
    return await getIdToken(getAuth().currentUser, forceRefresh)
}