const lbls = {
    load_pls_wait: "Die Daten werden geladen. Bitte warten...",
    save_pls_wait: "Die Daten werden gespeichert. Bitte warten...",
    del_pls_wait: "Die Daten werden gelöscht. Bitte warten...",

    save: "Speichern",
    cancel: "Abrechen",
    delete: "Löschen",

    admin: "Administrator",
    name: "Name",
    prj: "Projekt",
    email: "E-Mail",
    phone: "Telefon",
    title: "Titel",
    fName: "Vorname",
    lName: "Nachname",
    pers_info: "Benutzerinformationen",
    admin_rolle_hd: "Unternehmensverwaltung",
    rollen: "Rollen",

    tab_usr_data: "Benutzerinformationen",
    tab_prj_roles: "Projekte und Rollen",

    search: "Suchen...",
    search_mandanten: "Mandanten suchen...",
    search_fil: "Filialen suchen...",

    tenants_root: "Mandanten",
    tenants_child: "Filialen",
    new_tnt_root: "Neuer Mandant",
    new_tnt_child: "Neue Filiale",

    tnt_data: "Daten",
    tnt_prj: "Projekte",

    kuerzel: "Kürzel",
    custNr: "Betrieb-Nr.",
    details: "Details",
    crmId: "Externe CRM-Id",
    siteType: "Standort-Type",
    arch: "Archiviert",

    address: "Adresse",
    street: "Straße",
    zip: "PLZ",
    city: "Stadt",

    contact: "Kontakt",
    phone2: "Telefon2",
    website: "Website",

    prj_with_mount: 'Projekte die "%mount" enthalten',
    prj_mount_loeschen: "Mandant aus Projekt entfernen",
    conf_del: "Wirklich entfernen?",

    tnt_parent_id: "Kopf-Mandant/Zentrale",
    tnt_choose_parent: "Unter welcher Kopf-Mandant/Zentrale verschieben?",
    tnt_make_root: "Als Kopf-Mandant/Zentrale definieren",
    tnt_make_root_filiale_warn1:
        "Vorsicht, der ausgewählte Mandant enthält Filialen. Beim Umzug dieses Mandanten unter einen anderen Kopf-Mandant/Zentrale können die zugeordneten Filialen in iNoRisk nicht mehr berücksichtigt werden.",
    tnt_make_root_filiale_warn2:
        "Empfehlung: Behalten Sie den ausgewählten Mandant als Kopf-Mandant/Zentrale, sodass seine Filialen sichtbar bleiben. Bei Bedarf ziehen Sie zuerst die zugeordneten Filialen zu einem anderen Kopf-Mandant um. Anschließend können Sie den ausgewählten Mandant auch umziehen!",
    tnt_conf_del:
        "ACHTUNG! Der Mandant mit alle seinen Daten, Bilder und Unterschiften werden unwiderruflich gelöscht! Diese Aktion kann nicht mehr rückgängig gemacht werden! Wirklich entfernen?",

    change: "Ändern",
};

export default lbls;
