import { get, orderBy } from 'lodash'
import { observable, values } from 'mobx'
import root from '../'

/* CUSTOMERS */
const _store = observable({

    get MyAdmCusts() {

        const myUid = root.auth.AuthUid

        return orderBy(
            values(this).filter(c => get(c, ['rel', 'users', myUid])),
            [it => get(it, ['data', 'name'], '').toLowerCase()]
        )
    },
})

export default _store