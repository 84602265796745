import React from 'react'
import { observer } from 'mobx-react-lite'
import Toast from 'react-bootstrap/Toast'
import { Spin, Space } from 'antd'
import rootStore from '../../store'

function WorkProgressFeedback() {

    let msg

    const lbl = rootStore.session.Lbls

    if (rootStore.workProgress.hasPushingTasks) {
        msg = lbl.save_pls_wait
    }
    else if (rootStore.workProgress.hasLoadingTasks) {
        msg = lbl.load_pls_wait
    }
    else {
        return null
    }

    return <Toast style={{ position: 'absolute', bottom: 80, right: 30, zIndex: 9999 }} show >
        <Toast.Header closeButton={false} className="pt-2 pb-2">
            <Space size="small">
                <Spin size="small" />
                <div className="mr-auto text-info">
                    {msg}
                </div>
            </Space>
        </Toast.Header>
    </Toast>

}

export default observer(WorkProgressFeedback)